import React, { useRef, useState } from 'react';
import ImageWMS from 'ol/source/ImageWMS';
import LayerFilter from '../LayerFilter/LayerFilter';
import LayerFilterMin from '../LayerFilterMin/LayerFilterMin';
import MetaLink from '../MetaLink/MetaLink';
import ShapeLink from '../ShapeLink/ShapeLink';
import { ServerTypeHelper } from '../../../../_config/helpers';
import './LayerFolder.css';
import openedFolder from '../../../../_assets/img/openedFolder.png';
import closedFolder from '../../../../_assets/img/closedFolder.png';

const LayerFolder = (props) => {

  const content = useRef();

  const titleUnicode = useRef();

  const [toggle, setToggle] = useState(false);

  const [zIndexer, setZIndexer] = useState(props.map.getLayers().getArray().length);

  const clientWidth = document.documentElement.clientWidth;

  const clientHeight = document.documentElement.clientHeight;

  const handleLayerClick = (event) => {

    const layerId = event.target.value;

    const layer = props.map.getLayers().getArray()[layerId];

    layer.setVisible(!layer.getVisible());

    if (layer.getVisible())
      props.hasNewVisibleLayer(layerId);
    else
      props.hasNewVisibleLayer(-layerId);

    let label = (document.querySelector(`#layerTitle${layerId}`));
    
    label.style.color = `#FFFFFF`;

    handleLayerZIndexer(layer);

    handleLayerZoomFocus(layer);

    if (clientWidth <= 900)
      handleGetLegendGraphic(layer, layerId);

  }

  const handleGetLegendGraphic = (layer, index) => {

    if (layer.getVisible() && (layer.get('serverType') === ServerTypeHelper.GEOSERVER || layer.get('serverType') === ServerTypeHelper.MAPSERVER)) {

      const wmsSource = new ImageWMS({
        url: layer.get('url'),
        params: { 'LAYERS': layer.get('wmsName') },
        ratio: 1,
        serverType: layer.get('serverType'),
      });

      const graphicUrl =
        wmsSource.getLegendUrl(props.map.getView().getResolution(),
          { "legend_options": "bgColor:#212835;fontColor:#FFFFFF;forceLabels:on;" },
        );
      const img = document.getElementById('layerLegend' + index);
      const legendTitle = document.getElementById('layerLegendTitle' + index);
      img.src = graphicUrl;
      img.onclick = () => window.open(graphicUrl, '_blank').focus();
      img.style.visibility = 'visible';
      img.style.display = 'inherit';
      img.style.cursor = 'pointer';
      legendTitle.style.visibility = 'visible';
      legendTitle.style.display = 'inherit';
    } else if (!layer.getVisible()) {
      const legendTitle = document.getElementById('layerLegendTitle' + index);
      const img = document.getElementById('layerLegend' + index);
      img.style.visibility = 'hidden';
      img.style.display = 'none';
      legendTitle.style.visibility = 'hidden';
      legendTitle.style.display = 'none';
    }


  }

  const handleFolderClick = () => {

    if (toggle) {
      content.current.style.visibility = 'hidden';

      content.current.style.display = 'none';

      content.current.style.opacity = '0';

      setToggle(false);
    } else {
      content.current.style.visibility = 'visible';

      content.current.style.display = 'inherit';

      content.current.style.opacity = '1';

      setToggle(true);
    }
  }

  const handleLayerZIndexer = (layer) => {

    const vector = props.map.getLayers().getArray()[props.map.getLayers().getArray().length - 4];
    const vectorForCSV = props.map.getLayers().getArray()[props.map.getLayers().getArray().length - 3];
    const vectorForGeolocation = props.map.getLayers().getArray()[props.map.getLayers().getArray().length - 2];
    const graticule = props.map.getLayers().getArray()[props.map.getLayers().getArray().length - 1];

    if (layer.getVisible()) {
      setZIndexer(zIndexer + 1)
      layer.setZIndex(zIndexer);
      vector.setZIndex(zIndexer + 1)
      vectorForCSV.setZIndex(zIndexer + 2)
      vectorForGeolocation.setZIndex(zIndexer + 3)
      graticule.setZIndex(zIndexer + 4);
    }

  }

  const isScoped = () => {

    let layerFolderImage = (document.querySelector(`#folder${props.folderIndex}image`));
    
    layerFolderImage.style.color = '#FFFFFF';

    if (content.current.style.visibility === `visible`) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }

  const handleLayerZoomFocus = async (layer) => {

    let method = 'GET'
    let url = 'https://pedea.sema.ce.gov.br/api/v1/geoextent/layer?layer=' + layer.get('wmsName').split(':')[1]
    let xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {

      let str = xhr.response;

      let regex = /[-\d]{1,}.[\d]{1,}/g;

      let extent = str.match(regex);

      if (extent && extent[0] && extent[1] && extent[2] && extent[3]) {
        try {
          extent = extent.map((e) => parseFloat(e));

          props.map.getView().fit(extent, props.map.getSize());
        } catch (e) {
          console.log(e)
        }
      }
    };
    xhr.onerror = function () {
      console.log(xhr.response);
    };
    xhr.send();

  }

  return (
    <div onMouseOver={isScoped} className='LayerFolder' id={`folder${props.folderIndex}`}>
      <h3 className='LayerFolderTitle' onClick={handleFolderClick}>
        <span className='LayerFolderTitleIcon' ref={titleUnicode}>
          <i
            className="material-icons"
            alt="Pasta"
            ref={titleUnicode}
            id={`folder${props.folderIndex}image`}
          >
            {toggle ? "folder_open" : "folder"}
          </i>
        </span>
        <span className='LayerFolderTitleText'>
          {props.title}
        </span>
      </h3>
      <div ref={content} className='content'>
        {props.map.getLayers().getArray().map((layer, index) =>

          layer.get('folderSequence') === props.folderSequence ?

            <div className='LayerFolderItem' key={index}>


              <div className="LayerInputContainer">
                <div className='LayerCheckContainer'>
                  <input className='LayerCheck form-check-input' type='checkbox' defaultChecked={layer.getVisible() && layer.get('title') !== 'Grade'} id={'layer' + index} value={index} onClick={handleLayerClick} />
                </div>
                <div className='LayerTitleContainer'>
                  <span className='LayerTitle' id={'layerTitle' + index}>{layer.get('title')}</span>
                </div>
              </div>

              <div className='LayerActions' key={'LayerActions' + index}>
                <MetaLink wmsName={layer.get('wmsName')} />
                <ShapeLink wmsName={layer.get('wmsName')} />

                {clientWidth > 550 ?
                  (<LayerFilter layer={layer} wmsName={layer.get('wmsName')} />)
                  :
                  (<LayerFilterMin layer={layer} wmsName={layer.get('wmsName')} />)}

                {clientWidth <= 900 || clientHeight <= 750 ?
                  (<><span id={'layerLegendTitle' + index} className='LayerLegendTitle'>Legenda: </span><img alt="N/A" id={'layerLegend' + index} className='LayerLegend' /></>)
                  :
                  null}
              </div>

            </div>
            : null
        )}
      </div>

    </div>
  );
}


export default LayerFolder;
