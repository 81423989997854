import React, { useState, useRef, useEffect } from 'react';
import './LayerFilter.css';
import show_filter from '../../../../_assets/img/show_filter.png';
import show_filter_min from '../../../../_assets/img/show_filter_min.png';


const LayerFilter = (props) => {

  const img = document.documentElement.clientWidth > 600 ? show_filter : show_filter_min;

  const wmsName = props.wmsName;

  const layerFilterHandlerRef = useRef();

  const layerInputAttributeSelect = useRef();

  const layerInputOperatorSelect = useRef();

  const layerFilterInput = useRef();

  const [layerOperators, setLayerOperators] = useState([]);

  const [layerAttributesOptions, setLayerAttributesOptions] = useState([]);

  const [layerAttributes, setLayerAttributes] = useState([]);

  const [layerAttributesTypes, setLayerAttributesTypes] = useState([]);

  const handleLayerFilterClick = (e) => {

    let display = (layerFilterHandlerRef.current.style.display);

    if (display === 'none' || display === '') {

      if (layerAttributes.length == 0)
        fetchLayerAttributes();

      layerFilterHandlerRef.current.style.display = 'block';
    } else {
      layerFilterHandlerRef.current.style.display = 'none';
    }

  }

 const handleLayerFilterUpdaterClick = (e) => {

    let attribute = layerAttributes[(layerInputAttributeSelect.current.value)].replace(":", "");
    let attributeType = layerAttributesTypes[(layerInputAttributeSelect.current.value)];
    let operator = (layerInputOperatorSelect.current.value);
    let filterValue = formatFilterValue(attributeType, operator, layerFilterInput.current.value);

    var filter = attribute+" "+operator+" "+filterValue;

    //console.log(filter)
    // by default, reset all filters
    var filterParams = {
      'FILTER': null,
      'CQL_FILTER': null,
      'FEATUREID': null
    };

    if (filter.replace(/^\s\s*/, '').replace(/\s\s*$/, '') != "") {
      filterParams["CQL_FILTER"] = filter;
    }
    // merge the new filter definitions
    props.layer.getSource().updateParams(filterParams);

  }

  const formatFilterValue = (type, operator, filterValue) => {

    //console.log(type)

    if(type.search(`double`)!==-1 || type.search(`int`)!==-1 || type.search(`numeric`)!==-1 || type.search(`decimal`)!==-1 || type.search(`real`)!==-1 || type.search(`serial`)!==-1)
      return filterValue;
    else if(operator === `ilike`)
      return `'%${filterValue}%'`;
    else
      return `'${filterValue}'`;

  }

  const handleLayerFilterReseterClick = () => {

    var filterParams = {
      'FILTER': null,
      'CQL_FILTER': null,
      'FEATUREID': null
    };

    props.layer.getSource().updateParams(filterParams);

  }

  const fetchLayerAttributes = async () => {


    let method = 'GET'
    let url = 'https://pedea.sema.ce.gov.br/api/v1/attributes/layer?layer=' + wmsName.split(':')[1]
    let xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {

      let str = xhr.response;

      let attributes = str.match(/[\w]{1,}:/g);

      let attributesTypes = str.match(/[\w]{1,};/g);

      if (attributes && attributes[0] && attributesTypes && attributesTypes[0]) {
        try {
          setLayerAttributes(attributes);
          setLayerAttributesTypes(attributesTypes);
          setLayerOperators(buildLayerOperators());
        } catch (e) {
          console.log(e)
        }
      }
    };
    xhr.onerror = function () {
      console.log(xhr.response);
    };
    xhr.send();

  }

  const buildLayerAttributeOption = (attribute, id) => {

    attribute = attribute.replace(":", "");

    return (<option key={id} className={'LayerOperator'} value={id}>{attribute}</option>);

  }

  const buildLayerAttributeOptions = () => {

    return setLayerAttributesOptions(layerAttributes.map((attribute, key) => buildLayerAttributeOption(attribute, key)));

  }

  const buildLayerOperator = (operator, id) => {

    return (<option key={id} className={'LayerOperator'} value={operator[0]}>{operator[1]}</option>);

  }

  const buildLayerOperators = () => {

    const layerOperators = [[`=`,`Igual`], [`ilike`,`Contém`], [`<>`,`Diferente`], [`>`,`Maior`], [`>=`,`Maior igual`], [`<`,`Menor`], [`<=`,`Menor igual`]]

    return (layerOperators.map((operator, key) => buildLayerOperator(operator, key)));

  }

  useEffect(buildLayerAttributeOptions, [layerAttributes]);

  return (
    <>
      <a className={"LayerFilter"} href='#' rel="noreferrer">
      <button className='HorizontalButton'
          type="button"
          onClick={handleLayerFilterClick}
          title="Filtrar camada"
          alt="Filtrar camada"
        >
          <i className="material-icons">filter_alt</i>
        </button>
      </a>
      <div id="LayerFilterHandler" ref={layerFilterHandlerRef} className={"LayerFilterHandler"}>

        <span>Filtro: </span>

        <div className="input-group mb-3">
          <select ref={layerInputAttributeSelect} className={"form-select LayerFilterHandlerInput"}>
            <option value={``}>Atributo...</option>
            {layerAttributesOptions}
          </select>
          <span className="input-group-text"></span>
          <select ref={layerInputOperatorSelect} className={"form-select LayerFilterHandlerInput"}>
            <option value={``}>Operador...</option>
            {layerOperators}
          </select>
        </div>

        <div className="input-group mb-3">
          <input ref={layerFilterInput} placeholder={"Ditie um valor..."} className={"form-control LayerFilterHandlerInput"}></input>
          <button className="btn btn-secondary mb3" onClick={handleLayerFilterUpdaterClick} type="button" id="LayerFilterUpdater button-addon1">Filtrar</button>
          <span className="input-group-text"></span>
          <button className="btn btn-secondary mb3" onClick={handleLayerFilterReseterClick} type="button" id="LayerFilterReseter button-addon1">Resetar</button>
        </div>

      </div>
    </>
  );
}

export default LayerFilter;
